<template>
<v-container fluid>
  <RightEditor ref="rightKrEditor" />
  <CreateProjectDlg @create="createNewItem" ref="createItemDlg" />
  <CreateTask ref="createTask" @save="createTask" />
  <div v-for="kr in currentOkr.ob_results" :key="kr.kr_id">
    <v-row class="d-flex">
      <span class="blue--text ma-2">kr{{ kr.kr_order }}</span>
      <span @click="openKrEditor(kr,selectedObj.ob_status)" style="cursor: pointer;" class="text-subtitle-1">{{ kr.kr_name }}</span>
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text small>+新建</v-btn>
          </template>
          <v-list v-if="!disableEdit">
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="openCreateTask(kr)" small plain>
                  添加任务
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="createItem(kr)" small plain>添加项目</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small text dark v-bind="attrs" v-on="on">
              关联
            </v-btn>
          </template>
          <v-list v-if="!disableEdit">
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="assignTaskForKr(kr)" small plain>关联任务</v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn @click="assignItemForKr(kr)" small plain>关联项目</v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <!-- show items, 显示项目 -->
    <v-row v-if="kr.kr_items.length>0">
      <zk-table 
        :data="kr.kr_items" 
        :columns="itemHeader" 
        children-prop="task_tasks"
        index-text="#"
        :stripe="props.stripe"
        :border="props.border"
        :show-header="props.showHeader"
        :show-summary="props.showSummary"
        :show-row-hover="props.showRowHover"
        :show-index="props.showIndex"
        :tree-type="props.treeType"
        :is-fold="props.isFold"
        :expand-type="props.expandType"
        :selection-type="props.selectionType">
        <template slot="itemName" scope="scope">
          <v-btn :to="`${routes.ITEM_DETAIL_PAGE}?id=${scope.row.item_id}`" small text>
            <v-icon class="mr-3">mdi-calendar-clock</v-icon>
            <span class="pt-2 text-subtitle-2">{{ scope.row.item_name }}</span>
          </v-btn>
        </template>
        <template slot="itemOwner" scope="scope">
          <div class="d-flex">
            <v-icon class="mt-2">mdi-account-outline</v-icon>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="mt-2" v-bind="attrs" v-on="on" style="width:50px;cursor:pointer;" >{{ scope.row.item_owner | username }}</div>
              </template>
              <v-card>
                <UserPicker @pick="changeItemOwner($event, scope.row.item_id)" />
              </v-card>
            </v-menu>
          </div>          
        </template>
        <template slot="itemDate" scope="scope">
          <date-range-picker ref="picker" opens="left" control-container-class="mt-2 small-date-picker" :locale-data="{ 
                firstDay: 1,
                format: 'yyyy-mm-dd',
                applyLabel: '确定',
                cancelLabel: '取消',
                daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            }" 
            minDate="2018-01-01" 
            maxDate="2050-12-31" 
            :dateRange="{startDate: scope.row.item_start_date, endDate: scope.row.item_end_date}" 
            :ranges="false" 
            @update="changeItemDate($event, scope.row.item_id)"
            :append-to-body="true"
          >
            <template v-slot:input="picker" style="min-width: 350px;border:none;">
              {{ getDate(picker.endDate) }}
            </template>
          </date-range-picker>
        </template>
        <template slot="itemProgress" scope="scope">
          <v-progress-circular class="mr-5" color="primary" :size="35" :value="scope.row.item_progress"><span class="text-caption">{{ scope.row.item_progress }}%</span></v-progress-circular>
        </template>
        <template slot="itemUnlink" scope="scope">
          <v-icon @click="unlinkKrItem(scope.row)" small>mdi-link-off</v-icon>
        </template>
      </zk-table>
    </v-row>
    <!-- show kr tasks, 显示任务 -->
    <v-row v-if="kr.kr_tasks.length>0">
      <zk-table 
        :data="kr.kr_tasks" 
        :columns="taskHeaders" 
        children-prop="task_tasks"
        index-text="#"
        :stripe="props.stripe"
        :border="props.border"
        :show-header="props.showHeader"
        :show-summary="props.showSummary"
        :show-row-hover="props.showRowHover"
        :show-index="props.showIndex"
        :tree-type="props.treeType"
        :is-fold="props.isFold"
        :expand-type="props.expandType"
        :selection-type="props.selectionType">
        <template slot="taskState" scope="scope">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab class="mt-2" small text v-bind="attrs" v-on="on">
                <v-icon small>{{taskStatus[scope.row.task_status-1].icon}}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="state in taskStatus" :key="state.value">
                <v-list-item-title>
                  <v-btn @click="changeStatus(scope.row.task_id, state.value)" small text>
                    <v-icon small>{{ state.icon }}</v-icon>{{ state.text }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template slot="taskPriority" scope="scope">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mt-2" :color="palette[scope.row.task_priority-1]" fab small text v-bind="attrs" v-on="on">
                p{{ scope.row.task_priority }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item :key="i" v-for="(pr, i) in priority">
                <v-list-item-title>
                  <v-btn @click="changePriority(i+1, scope.row.task_id)" :color="pr.color" small text><span class="mr-3 pb-1">p{{i+1}}</span><span class="pb-1">{{ pr.text }}</span></v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>    
        <template slot="taskName" scope="scope">          
          <div class="mt-2" style="cursor:pointer;" @click="openTask(scope.row)">{{ scope.row.task_name }}</div>
        </template>
        <template slot="taskOwner" scope="scope">
          <div class="d-flex">
            <v-icon class="mt-2">mdi-account-outline</v-icon>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="mt-2" style="width:50px;cursor:pointer;" v-bind="attrs" v-on="on">{{ scope.row.task_owner | username }}</div>
              </template>
              <v-card>
                <UserPicker @pick="changeOwner($event, scope.row.task_id)" />
              </v-card>
            </v-menu>
          </div>          
        </template>
        <template slot="taskDate" scope="scope">          
          <date-range-picker ref="picker" opens="left" control-container-class="mt-2 small-date-picker" :locale-data="{ 
                firstDay: 1,
                format: 'yyyy-mm-dd',
                applyLabel: '确定',
                cancelLabel: '取消',
                daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            }" 
            minDate="2018-01-01" 
            maxDate="2050-12-31" 
            :dateRange="{startDate: scope.row.task_start_date, endDate: scope.row.task_end_date}" 
            :ranges="false" 
            @update="changeTaskDate($event, scope.row.task_id)"
            :append-to-body="true"
          >
            <template v-slot:input="picker" style="min-width: 350px;border:none;">
              {{ getDate(picker.endDate)  }}
            </template>
          </date-range-picker>          
        </template>
        <template slot="taskProgress" scope="scope">
          <v-progress-circular class="mr-5" color="primary" :size="35" :value="scope.row.task_progress"><span class="text-caption">{{ scope.row.task_progress }}%</span></v-progress-circular>
        </template>
        <template slot="taskUnlink" scope="scope">
          <v-icon @click="unlinkTask(scope.row)" small>mdi-link-off</v-icon>
        </template>                    
      </zk-table>
    </v-row>
    <v-row v-if="kr.kr_tasks.length==0 && kr.kr_items.length==0">
      <div class="mx-12 text-caption orange--text">用「任务/项目」来支撑，OKR 落地更有保障 💪</div>
    </v-row>
    <v-row class="my-7">
      <v-divider></v-divider>
    </v-row>
  </div>
  <v-row class="my-3">
    <span class="text-subtitle-1">目标下的其他执行</span>
    <v-spacer></v-spacer>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" text small >+新建</v-btn>
        </template>
        <v-list v-if="!disableEdit">
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="openObjectTask" small plain>
                添加任务
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="openObjectItem" small plain>添加项目</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" small text dark v-bind="attrs" v-on="on"  >
            关联
          </v-btn>
        </template>
        <v-list v-if="!disableEdit">
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="assignTaskForObject" small plain>关联任务</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="assignItemForObject" small plain>关联项目</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-row>
  <!-- show items, 显示项目 -->
  <v-row v-if="currentOkr.ob_items>0">
    <zk-table 
      :data="currentOkr.ob_items" 
      :columns="itemHeader" 
      children-prop="task_tasks"
      index-text="#"
      :stripe="props.stripe"
      :border="props.border"
      :show-header="props.showHeader"
      :show-summary="props.showSummary"
      :show-row-hover="props.showRowHover"
      :show-index="props.showIndex"
      :tree-type="props.treeType"
      :is-fold="props.isFold"
      :expand-type="props.expandType"
      :selection-type="props.selectionType">
      <template slot="itemName" scope="scope">
        <v-btn :to="`${routes.ITEM_DETAIL_PAGE}?id=${scope.row.item_id}`" small text>
          <v-icon class="mr-3">mdi-calendar-clock</v-icon>
          <span class="pt-2 text-subtitle-2">{{ scope.row.item_name }}</span>
        </v-btn>
      </template>
      <template slot="itemOwner" scope="scope">
        <div class="d-flex">
          <v-icon class="mt-2">mdi-account-outline</v-icon>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="mt-2" v-bind="attrs" v-on="on" style="width:50px;cursor:pointer;" >{{ scope.row.item_owner | username }}</div>
            </template>
            <v-card>
              <UserPicker @pick="changeItemOwner($event, scope.row.item_id)" />
            </v-card>
          </v-menu>
        </div>          
      </template>
      <template slot="itemDate" scope="scope">
        <date-range-picker ref="picker" opens="left" control-container-class="mt-2 small-date-picker" :locale-data="{ 
              firstDay: 1,
              format: 'yyyy-mm-dd',
              applyLabel: '确定',
              cancelLabel: '取消',
              daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
              monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          }" 
          minDate="2018-01-01" 
          maxDate="2050-12-31" 
          :dateRange="{startDate: scope.row.item_start_date, endDate: scope.row.item_end_date}" 
          :ranges="false" 
          @update="changeItemDate($event, scope.row.item_id)"
          :append-to-body="true"
        >
          <template v-slot:input="picker" style="min-width: 350px;border:none;">
            {{ getDate(picker.endDate) }}
          </template>
        </date-range-picker>
      </template>
      <template slot="itemProgress" scope="scope">
        <v-progress-circular class="mr-5" color="primary" :size="35" :value="scope.row.item_progress"><span class="text-caption">{{ scope.row.item_progress }}%</span></v-progress-circular>
      </template>
      <template slot="itemUnlink" scope="scope">
        <v-icon @click="unlinkObjectItem(scope.row)" small>mdi-link-off</v-icon>
      </template>
    </zk-table>
  </v-row>
  <!-- show tasks, 显示任务 -->
  <v-row v-if="currentOkr.ob_tasks.length>0">
    <zk-table 
      :data="currentOkr.ob_tasks" 
      :columns="taskHeaders" 
      children-prop="task_tasks"
      index-text="#"
      :stripe="props.stripe"
      :border="props.border"
      :show-header="props.showHeader"
      :show-summary="props.showSummary"
      :show-row-hover="props.showRowHover"
      :show-index="props.showIndex"
      :tree-type="props.treeType"
      :is-fold="props.isFold"
      :expand-type="props.expandType"
      :selection-type="props.selectionType">
      <template slot="taskState" scope="scope">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab class="mt-2" small text v-bind="attrs" v-on="on">
              <v-icon small>{{taskStatus[scope.row.task_status-1].icon}}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="state in taskStatus" :key="state.value">
              <v-list-item-title>
                <v-btn @click="changeStatus(scope.row.task_id, state.value)" small text>
                  <v-icon small>{{ state.icon }}</v-icon>{{ state.text }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template slot="taskPriority" scope="scope">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-2" :color="palette[scope.row.task_priority-1]" fab small text v-bind="attrs" v-on="on">
              p{{ scope.row.task_priority }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item :key="i" v-for="(pr, i) in priority">
              <v-list-item-title>
                <v-btn @click="changePriority(i+1, scope.row.task_id)" :color="pr.color" small text><span class="mr-3 pb-1">p{{i+1}}</span><span class="pb-1">{{ pr.text }}</span></v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>    
      <template slot="taskName" scope="scope">          
        <div class="mt-2" style="cursor:pointer;" @click="openTask(scope.row)">{{ scope.row.task_name }}</div>
      </template>
      <template slot="taskOwner" scope="scope">
        <div class="d-flex">
          <v-icon class="mt-2">mdi-account-outline</v-icon>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="mt-2" style="width:50px;cursor:pointer;" v-bind="attrs" v-on="on">{{ scope.row.task_owner | username }}</div>
            </template>
            <v-card>
              <UserPicker @pick="changeOwner($event, scope.row.task_id)" />
            </v-card>
          </v-menu>
        </div>          
      </template>
      <template slot="taskDate" scope="scope">          
        <date-range-picker ref="picker" opens="left" control-container-class="mt-2 small-date-picker" :locale-data="{ 
              firstDay: 1,
              format: 'yyyy-mm-dd',
              applyLabel: '确定',
              cancelLabel: '取消',
              daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
              monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
          }" 
          minDate="2018-01-01" 
          maxDate="2050-12-31" 
          :dateRange="{startDate: scope.row.task_start_date, endDate: scope.row.task_end_date}" 
          :ranges="false" 
          @update="changeTaskDate($event, scope.row.task_id)"
          :append-to-body="true"
        >
          <template v-slot:input="picker" style="min-width: 350px;border:none;">
            {{ getDate(picker.endDate)  }}
          </template>
        </date-range-picker>          
      </template>
      <template slot="taskProgress" scope="scope">
        <v-progress-circular class="mr-5" color="primary" :size="35" :value="scope.row.task_progress"><span class="text-caption">{{ scope.row.task_progress }}%</span></v-progress-circular>
      </template>
      <template slot="taskUnlink" scope="scope">
        <v-icon @click="unlinkObjectTask(scope.row)" small>mdi-link-off</v-icon>
      </template>                    
    </zk-table>
  </v-row>
  <v-row v-if="currentOkr.ob_tasks.length==0 && currentOkr.ob_items.length==0">
    <div class="mx-12 text-caption orange--text">用「任务/项目」来支撑，OKR 落地更有保障 💪</div>
  </v-row>
  <TaskPicker ref="taskPicker" />
  <ItemPicker ref="itemPicker" />
	<TaskEdit ref="taskEditor" />
	<ConfirmDlg ref="confirmDlg" />
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  Routes
} from '@/constants/routes'
export default {
  name: 'ExecuteComponent',
  components: {
    CreateTask: () => import('@/components/common/CreateTask.vue'),
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    CreateProjectDlg: () => import('@/components/common/CreateProjectDlg.vue'),
    DateRangePicker,
    TaskPicker: () => import('./TaskPicker.vue'),
    ItemPicker: () => import('./ItemPicker.vue'),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
		TaskEdit: () => import('@/components/common/taskEdit/TaskEdit.vue'),
		ConfirmDlg: () => import('@/components/common/ConfirmDlg.vue'),
  },
  props: ['object_id','disableEdit'],
  watch: {
    object_id: function() {
      this.getDetail(this.object_id)
    }
  },
  computed: {
    ...mapGetters('okr', ['okrDetail','okrObj']),
    ...mapGetters('user', ['users']),
    currentOkr: function () {
      return this.okrDetail
    },
    selectedObj: function () {
      const selectedObj = this.okrObj.filter(obj => obj.ob_id == this.object_id);
      return selectedObj[0]
    },
  },
  data() {
    return {
      routes: Routes,
      taskMenu: false,
      newTask: {},
      palette: [
        'red', 'yellow', 'blue', 'green', 'gray'
      ],
      priority: [{
          text: '最高',
          color: 'red'
        },
        {
          text: '高',
          color: 'yellow'
        },
        {
          text: '中',
          color: 'blue'
        },
        {
          text: '低',
          color: 'green'
        },
        {
          text: '非常低',
          color: 'gray'
        },
      ],
      taskStatus: [{
          icon: 'mdi-check',
          text: '已完成',
          value: 1
        },
        {
          icon: 'mdi-timer-sand-empty',
          text: '进行中',
          value: 2
        },
        {
          icon: 'mdi-close',
          text: '已取消',
          value: 3
        },
        {
          icon: 'mdi-clock-outline',
          text: '已延迟',
          value: 4
        },
        {
          icon: 'mdi-pause',
          text: '暂停中',
          value: 5
        },
        {
          icon: 'mdi-checkbox-blank-outline',
          text: '未开始',
          value: 6
        }
      ],
      props: {
        stripe: false,
        border: false,
        showHeader: false,
        showSummary: false,
        showRowHover: false,
        showIndex: false,
        treeType: true,
        isFold: true,
        expandType: false,
        selectionType: false,
      },
      taskHeaders: [
        {
          label: '',
          prop: '',
          width: '50px'
        },
        {
          label: 'taskState',
          type: 'template',
          template: 'taskState',
          width: '40px'
        },
        {
          label: 'taskPriority',
          type: 'template',
          template: 'taskPriority',
          width: '40px'
        },
        {
          label: 'taskName',
          type: 'template',
          template: 'taskName'
        },
        {
          label: 'taskOwner',
          type: 'template',
          template: 'taskOwner',
          width: '150px'
        },
        {
          label: 'taskDate',
          type: 'template',
          template: 'taskDate',
          width: '50px'
        },
        {
          label: 'taskProgress',
          type: 'template',
          template: 'taskProgress',
          width: '60px'
        },
        {
          label: 'taskUnlink',
          type: 'template',
          template: 'taskUnlink',
          width: '40px'
        }
      ],
      itemHeader: [
        {
          label: '',
          prop: '',
          width: '50px'
        },
        {
          label: 'itemName',
          type: 'template',
          template: 'itemName'
        },
        {
          label: 'itemOwner',
          type: 'template',
          template: 'itemOwner',
          width: '120px'
        },
        {
          label: 'itemDate',
          type: 'template',
          template: 'itemDate',
          width: '50px'
        },
        {
          label: 'itemProgress',
          type: 'template',
          template: 'itemProgress',
          width: '60px'
        },
        {
          label: 'itemUnlink',
          type: 'template',
          template: 'itemUnlink',
          width: '40px'
        }
      ]
    }
  },
  methods: {
    ...mapActions('task', ['addTask', 'updateTaskDetail', 'removeTaskParent']),
    ...mapActions('okr', ['getDetail']),
    ...mapActions('item', ['addItem', 'updateItem', 'removeItemParent']),
    createTask(e, kr) {
      this.newTask = e
      this.newTask = {
        ...this.newTask,
        task_parent_object: this.object_id
      }
      this.taskMenu = false
      this.addTask(this.newTask)
    },
    changeOwner(e, id) {
      const payload = {
        task_id: id,
        task_parent_object: this.object_id,
        task_owner: e.user.id
      }
      this.updateTaskDetail(payload)
    },
    changePriority(value, id) {
      const payload = {
        task_id: id,
        task_parent_object: this.object_id,
        task_priority: value
      }
      this.updateTaskDetail(payload)
    },
    changeStatus(id, value) {
      const payload = {
        task_id: id,
        task_parent_object: this.object_id,
        task_status: value
      }
      this.updateTaskDetail(payload)
    },
    createItem(kr) {
      this.$refs.createItemDlg.open({
        kr_id: kr.kr_id
      })
    },
    createNewItem(newItem) {
      const payload = Object.assign(newItem,{item_parent_object: this.object_id})
      this.addItem(payload)
    },
    changeItemOwner(e, id) {
      const payload = {
        item_id: id,
        item_parent_object: this.object_id,
        item_owner: e.user.id
      }
      this.updateItem(payload)
    },
    changeTaskDate(param, id) {
      let start = param.startDate;
      let end = param.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      const payload = {
        task_id: id,
        task_parent_object: this.object_id,
        task_start_date: start,
        task_end_date: end
      }
      this.updateTaskDetail(payload)
    },
    changeItemDate(param, id) {
      let start = param.startDate;
      let end = param.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      const payload = {
        item_id: id,
        item_parent_object: this.object_id,
        item_start_date: start,
        item_end_date: end
      }
      this.updateItem(payload)
    },
    getDate(date) {
      let temp = new Date(date)
      return (temp.getMonth() + 1) + '/' + (temp.getDate())
    },
    openCreateTask(kr) {
      this.$refs.createTask.open(kr)
    },
    openObjectTask() {
      this.$refs.createTask.open()
    },
    openObjectItem() {
      this.$refs.createItemDlg.open()
    },
    async unlinkTask(task) {

      if(!this.disableEdit){
        	if (await this.$refs.confirmDlg.open('确定取消关联吗？', '取消关联后该任务将不在此目标下显示', {})) {
            this.removeTaskParent({
              task_id: task.task_id,
              // task_parent_object: this.object_id,
              task_parent_object: 0,
              task_parent_kr: 0
            }).then(() => {
              this.getDetail(this.object_id)
            })
          }
       }
    },
    async unlinkKrItem(item) { 
       if(!this.disableEdit){
        if (await this.$refs.confirmDlg.open('确定取消关联吗？', '取消关联后该项目将不在此目标下显示', {})) {
          this.removeItemParent({
            item_id: item.item_id,
            item_parent_kr: 0,
            item_parent_object: 0
          }).then(() => {
            this.getDetail(this.object_id)
          })
        }
       }
    },
  async  unlinkObjectTask(task) {
      if (await this.$refs.confirmDlg.open('确定取消关联吗？', '取消关联后该任务将不在此目标下显示', {})) {
      this.removeTaskParent({
        task_id: task.task_id,
        task_parent_object: 0
      }).then(() => {
        this.getDetail(this.object_id)
      })
      }
    },
  async  unlinkObjectItem(item) {
    if (await this.$refs.confirmDlg.open('确定取消关联吗？', '取消关联后该项目将不在此目标下显示', {})) {
      this.removeItemParent({
        item_id: item.item_id,
        item_parent_object: 0
      }).then(() => {
        this.getDetail(this.object_id)
      })
    }
    },
    assignTaskForObject() {
      this.$refs.taskPicker.open({
        task_parent_object: this.object_id
      })
    },
    //新建 
    assignTaskForKr(kr) {  
      this.$refs.taskPicker.open({
        task_parent_kr: kr.kr_id,
        task_parent_object: this.object_id
      })
    },
    assignItemForObject() {
      this.$refs.itemPicker.open({
        item_parent_object: this.object_id
      })
    },
    // 关联任务
    assignItemForKr(kr) {
      this.$refs.itemPicker.open({
        item_parent_object: this.object_id,
        item_parent_kr: kr.kr_id
      })
    },
    openKrEditor(kr,status) {
      this.$refs.rightKrEditor.open({
        obId: this.object_id,
        krObj: kr,
        obStatus:status  // obj状态
      })
    },
    openTask(task) {
      // this.$router.push({path: `${Routes.TASK_DETAIL_PAGE}?id=${id}`});
			this.$refs.taskEditor.openTaskEditor(task);
    }
  },
  mounted() {
    this.getDetail(this.object_id)
  }
}
</script>

<style lang="css">
.small-date-picker {
  font-size: 14px;
  cursor: pointer;
}
.zk-table {
  border: none !important;
}
.zk-table__body-row {
  border: none !important;
}
</style>
