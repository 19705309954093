<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <div>周期</div>
        <div class="cycle-container obj-cycle mt-2 ml-2 d-flex justify-start align-center" @click="openCycleDatePicker" >
          <span>{{cycle}} <span class="ml-1">({{start}}~{{end}})</span></span>
          <div class="cyclePicker" v-if="showCycleDatePicker">
            <Calendar @pick="handle_getTimePeriod" :isWhole="false" />
            <v-divider class="my-2"></v-divider>
            <date-range-picker ref="picker" opens="center" :locale-data="{ 
                            firstDay: 1,
                            format: 'yyyy-mm-dd',
                            applyLabel: '确定',
                            cancelLabel: '取消',
                            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                        }" :minDate="minDate" :maxDate="maxDate" :dateRange="dateRange" :ranges="false" @update="datePicker">
              <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
              </template>
            </date-range-picker>
            <div class="mt-4 d-flex justify-end align-center">
              <v-spacer></v-spacer>
              <v-btn class="close mr-4" text @click="closeCyclePicker" dense depressed>取消</v-btn>
              <v-btn class="save" text @click="updateCycle" dense depressed>确定</v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
        <div>负责人</div>
        <div>
          <v-menu offset-y >
            <template v-slot:activator="{ on, attrs }"  >
              <span v-bind="attrs" v-on="on" class="d-flex justify-start align-center">
                <UserAvatar :name="employeeName.substring(0, 1)" width='30px' height='30px' />
                <span class="ml-2">{{employeeName}}</span>
              </span>
            </template>
            <v-list class="py-0" v-if="!disableEdit" >
              <v-list-item class="px-0">
                <UserPicker @pick="param => pickUser(param)" />
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="label">可见范围</div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="300" class="pl-5" v-bind="attrs" v-on="on" color="grey" outlined height="40">
              <v-row>
                <div v-if="currentOkr.ob_visible_type != 3" class="black--text">{{ visibleMode[currentOkr.ob_visible_type] }}</div>
                <span v-else class="select_data">
                  <!-- <div v-for="(user, i) in vRange.users" :key="user"><span v-if="i!=0">,</span>{{ user }}</div>
                  <div v-for="(depart, i) in vRange.departs" :key="depart">,{{ depart }}</div> -->
                  <ToolTipComponent :content="vRange" direction="top" :tooltipText=" vRange" />
                  </span>
                <v-spacer></v-spacer>
                <v-icon>mdi-menu-down</v-icon>
              </v-row>
            </v-btn>
          </template>
          <v-list v-if="!disableEdit">
            <v-list-item v-for="(type, i) in visibleMode">
              <v-btn @click="selectVisible(i)" class="justify-start" block text>{{ type }}</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- object type -->
      <v-col class="pt-7" style="position:relative; margin-top: -20px;">
        <div class="ml-3 mt-0">
          <div>类型</div>
          <v-btn @click="showTypeForm=true" height="40" width="267" color="grey" outlined>
            <div>{{ objectTypes[currentOkr.ob_type] }}</div>
            <v-spacer></v-spacer>
            <v-icon color="black">mdi-menu-down</v-icon>
          </v-btn>
        </div>
        <v-card v-if="showTypeForm && !disableEdit" width="300" style="position:absolute;left:25px;" min-height="120">
          <v-container fluid>
            <div class="d-flex mt-n4">
              <div class="text-caption grey--text">类型</div>
              <v-spacer></v-spacer>
              <v-radio-group row v-model="objectType">
                <v-radio class="mr-1" :value="0">
                  <template v-slot:label>
                    <div class="text-caption ml-n2">公司</div>
                  </template>
                </v-radio>
                <v-radio class="mr-1" :value="1">
                  <template v-slot:label>
                    <div class="text-caption ml-n2">部门</div>
                  </template>
                </v-radio>
                <v-radio class="mr-1" :value="2">
                  <template v-slot:label>
                    <div class="text-caption ml-n2">团队</div>
                  </template>
                </v-radio>
                <v-radio class="mr-1" :value="3">
                  <template v-slot:label>
                    <div class="text-caption ml-n2">个人</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex algin-center mt-n6" v-if="objectType==1">
              <div class="grey--text text-caption">部门:</div>
              <v-menu offset-y>
                <template v-slot:activator="{attrs, on}">
                  <div v-bind="attrs" v-on="on" class="d-flex" style="width:240px;border-bottom:1px solid;height:30px;">
                    <div v-if="currentOkr.ob_type_department">{{ currentOkr.ob_type_department | department}}</div>
                    <v-spacer></v-spacer>
                    <v-icon>mdi-menu-down</v-icon>
                  </div>
                </template>
                <department-picker @pick="setDepartment"></department-picker>
              </v-menu>
            </div>
            <div style="position:absolute;bottom:10px;right:10px;" class="d-flex">
              <v-btn @click="showTypeForm=false" class="mx-1" small>取消</v-btn>
              <v-btn @click="setObjectType" class="mx-1" small color="primary">确定</v-btn>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <div class="thickness my-4"></div>
  <div>
    <div>参与人</div>
    <div class="d-flex justify-start flex-wrap align-center">
      <span class="d-flex justify-start">
        <span class="d-flex align-center mr-2" v-for="user in selectedParticipants">
          <UserAvatar :name="getUserName(user).charAt(0)" width='30px' height='30px' :isRemove="true" @picked="removeParticipant(user)" />
        </span>
      </span>
      <v-icon large class="mr-4" @click="participantOpen" >mdi-plus-circle-outline</v-icon>
    </div>
  </div>
  <div class="mt-4">
    <div>关注人</div>
    <div class="d-flex justify-start flex-wrap align-center">
      <span class="d-flex justify-start">
        <span class="d-flex align-center mr-2" v-for="user in selectedFollowers">
          <UserAvatar :name="getUserName(user).charAt(0)" width='30px' height='30px' :isRemove="true" @picked="removeFollower(user)" />
        </span>
      </span>
      <v-icon large class="mr-4" @click="followerOpen">mdi-plus-circle-outline</v-icon>
    </div>
  </div>
  <UserDepartmentTeamPicker ref="participant" @selected="participantSave" />
  <UserDepartmentTeamPicker ref="follower" @selected="followerSave" />
  <UserDepartmentTeamPicker ref="visibility" @selected="visibilitySelect"  /> 
</div>
</template>

<script>
import UserAvatar from '@/components/common/UserAvatar.vue';
import ToolTipComponent from '@/components/okrgoal/shared/ToolTipComponent.vue';
import UserPicker from '@/components/common/UserPicker.vue';
import DateRangePicker from 'vue2-daterange-picker';
import Calendar from '@/components/common/Calendar.vue';
import UserDepartmentTeamPicker from '@/components/common/UserDepartmentTeamPicker.vue';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  constant
} from '@/constants/constant.js';
import {
  getUser
} from '@/utils/authUser.service';
import {
  converter
} from '@/utils/converter.js';

export default {
  name: 'BasicInfoComponent',
  components: {
    UserAvatar,
    ToolTipComponent,
    UserPicker,
    DateRangePicker,
    Calendar,
    UserDepartmentTeamPicker,
    DepartmentPicker: () => import("@/components/common/DepartmentPicker.vue"),
  },
  props: ['object_id','disableEdit'],
  data() {
    return {
      selectedObj: Object,
      nameStr: '',
      // dateRange: {
      //     startDate: '2021-07-06',
      //     endDate: '2021-07-12'
      // },
      minDate: '2018-01-01',
      maxDate: '2050-12-31',
      visibleMode: ['全公司', '仅相关成员', '仅直接下属', '指定范围'],
      showCycleDatePicker: false,
      selected_year: '',
      selected_quarter: '',
      startDateSave: '',
      endDateSave: '',
      saveCycle: '',
      visibleType: '',
      participants: [],
      followers: [],
      currentVisibleRange: null,
      objectTypes: ['公司', '部门', '团队', '个人'],
      showTypeForm: false,
      objectType: 0,
    }
  },
  mounted() {},
  computed: {
    ...mapGetters('okr', ['okrObj']),
    ...mapGetters("user", ["users"]),
    ...mapGetters("department", ["departments"]),
    ...mapGetters('auth', ['authUser']),
    selectedParticipants: function () {
      if (!this.currentOkr.ob_participant) return [];
      return this.currentOkr.ob_participant.split(',').filter(u => u != '');
    },
    selectedFollowers: function () {
      if (!this.currentOkr.ob_attention) return [];
      return this.currentOkr.ob_attention.split(',').filter(u => u != '');
    },
    currentOkr: function () {
      const index = this.okrObj.findIndex(item => item.ob_id == this.object_id)
      this.objectType = this.okrObj[index].ob_type;
      return this.okrObj[index]
    },
    align_target: {
      get: function () {
        const index = this.okrObj.findIndex(item => item.ob_id == this.object_id)
        const okr = this.okrObj[index];
        if (okr.ob_visible_type == 3) {
          return constant.visibilityTypeArr[3];
        } else {
          return okr.ob_visible_range;
        }
      },
      set: function (newValue) {
        const index = this.okrObj.findIndex(item => item.ob_id == this.object_id)
        const okr = this.okrObj[index];
        okr.ob_visible_range = newValue;
      }
    },
    dateRange: function () {
      let start = this.currentOkr.ob_start_date;
      let end = this.currentOkr.ob_end_date;
      return {
        startDate: start + ' 00:00:00',
        endDate: end + ' 00:00:00'
      }
    },
    start: function () {
      let start = this.currentOkr.ob_start_date;
      start = start.split('-');
      return start[1] + '/' + start[2];
    },
    end: function () {
      let end = this.currentOkr.ob_end_date;
      end = end.split('-');
      return end[1] + '/' + end[2];
    },
    object_name: function () {
      if (this.okrObj.length !== 0) {
        const selectedObj = this.okrObj.filter(obj => obj.ob_id == this.object_id);
        this.selectedObj = selectedObj[0];
        return selectedObj[0].ob_name;
      } else {
        return {};
      }
    },
    employeeName: function () {
      if (this.okrObj.length !== 0) {
        const selectedObj = this.okrObj.filter(obj => obj.ob_id == this.object_id);
        return selectedObj[0].ob_owners.employeeName;
      } else {
        return '';
      }
    },
    startDate: function () {
      let start = this.currentOkr.ob_start_date;
      let startDate = new Date(start);
      let month = startDate.getMonth() + 1;
      let day = startDate.getDay();
      return month + '/' + day;
    },
    endDate: function () {
      let end = this.currentOkr.ob_end_date;
      let endDate = new Date(end);
      let month = endDate.getMonth() + 1;
      let day = endDate.getDay();
      return month + '/' + day;
    },
    cycle: function () {
      let cycle = this.currentOkr.ob_cycle;
      if (cycle != undefined) {
        cycle += '';
        let tmp = cycle.split('/');
        if (tmp.length == 2) {
          let year = tmp[0];
          let quater = '/' + tmp[1];
          let result = constant.cycleItems.filter(c => c.dbformat == quater);
          return year + '年' + result[0].title;
        } else {
          tmp = cycle.split('-');
          let year = tmp[0];
          let month = '-' + tmp[1];
          let result = constant.cycleItems.filter(c => c.dbformat == month);
          return year + '年' + result[0].title;
        }
      }
    },
    vRange: function () {
      const tempArray = this.currentOkr.ob_visible_range.split(',');
    //  let users = [];
    //  let departs = [];
      let result = '';
      tempArray.filter(id => {
        if (id.includes('u')) {
        //  users.push(id.substring(1));
        const username = this.getUserName(id.substring(1))
          result += username + ','
        } else if (id.includes('d')) {
        const departName = this.getDepartName(id.substring(1))
          result += departName + ','
        }
      });
      return result;
      // return {
      //   users: users,
      //   departs: departs
      // };
    },
     //是否有添加kr的权限判定
    //  disableEdit: function () {
    //   if (!!this.currentOkr) {
    //     //console.log(!this.currentOkr.ob_participant.includes(getUser().id),'this.currentOkr')
    //     return this.currentOkr.ob_status == 1 || getUser().id != this.currentOkr.ob_owner  &&   !this.currentOkr.ob_participant.includes(getUser().id) || this.authUser.user_role == 'user' ||  this.authUser.user_role == 'editor' //项目参与者也可以编辑kr  普通用户与后台管理员也不可编辑
    //   } else {
    //     return false;
    //   }
    // },
  },
  methods: {
    ...mapActions("okr", ["updateOkr", "createKeyResult"]),
    // ...mapGetters("department", ["departments"]),
    removeParticipant(id) {
      let followers = this.currentOkr.ob_participant.split(',');
      const index = followers.findIndex(user => user.id == id);
      followers.splice(index, 1);
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_participant: followers.join()
      })
    },
    removeFollower(id) {
      let followers = this.currentOkr.ob_attention.split(',');
      const index = followers.findIndex(user => user.id == id);
      followers.splice(index, 1);
      if (!this.disableEdit) {     //有编辑权限才可以删除关注人
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_attention: followers.join()
      })}
    },
    datePicker(param) {
      let start = param.startDate;
      let end = param.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.startDateSave = start;
      this.endDateSave = end;
    },
    updateCycle(ev) {
      let obj = {
        ob_id: this.object_id,
        ob_cycle: this.saveCycle,
        ob_start_date: this.startDateSave,
        ob_end_date: this.endDateSave
      }
      if (obj.ob_cycle == '' || obj.ob_start_date == '' || obj.ob_end_date == '') {

      } else {
        this.updateOkr(obj);
      }
      ev.stopPropagation();
      this.showCycleDatePicker = false;
    },
    closeCyclePicker(ev) {
      ev.stopPropagation();
      this.showCycleDatePicker = false;
    },
    openCycleDatePicker() {
      if (!this.disableEdit) {
      this.showCycleDatePicker = true;
      }
    },
    pickUser(param) {
      let obj = {
        ob_id: this.currentOkr.ob_id,
        ob_owner: param.user.id
      }
      this.updateOkr(obj).then((res)=>{
          if (res.status == 200) {
            this.currentOkr.ob_owners.employeeName = param.user.employeeName;
          }
          else{
            console.log(res.status);
          }
      });
      //this.updateOkr(obj);
      //this.currentOkr.ob_owners.employeeName = param.user.employeeName;
    },
    handle_getTimePeriod(data) {
      this.saveCycle = data.cycle;
    },
    //打开参与人对话框
    participantOpen() {
      if (!this.disableEdit) {   //允许编辑权限才可使用
      this.$refs.participant.openDialog(this.currentOkr.ob_participant);
      }
    },
    //打开关注者对话框
    followerOpen() {
      if (!this.disableEdit) {   //允许编辑权限才可使用
      this.$refs.follower.openDialog(this.currentOkr.ob_attention);
      }
    },
    userData(data) {
      let param = data
      const flatten = (dp_departments, extractChildren) => Array.prototype.concat.apply(
        dp_departments,
        dp_departments.map(x => flatten(extractChildren(x) || [], extractChildren))
      );
      const extractChildren = x => x.dp_departments;
      if (!!param.id) {
        let temp = this.users.filter(user => user.deptId === param.id)
        if (!!param.dp_departments) {
          const flat = flatten(extractChildren(param), extractChildren).map(x => delete x.children && x);
          flat.map(item => {
            temp = temp.concat(this.users.filter(user => user.deptId === item.id))
          })
        }
        return temp.filter(user => user.status != 0);
      } else {
        return this.users.filter(user => user.status != 0)
      }
    },
    participantSave(data) {
      let followers = [];
      let temp = [];
      data.depart.map(depart => {
        temp = temp.concat(this.users.filter(user => user.deptId == depart.id));
      })
      temp.map(user => {
        followers.push(user.id);
      })
      for (let i = 0; i < data.user.length; i++) {
        if (followers.includes(data.user[i].id)) {
          continue;
        }
        followers.push(data.user[i].id);
      }
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_participant: followers.join()
      })
    },
    followerSave(data) {
      // get users by department
      let followers = [];
      let temp = [];
      data.depart.map(depart => {
        temp = temp.concat(this.users.filter(user => user.deptId == depart.id));
      })
      temp.map(user => {
        followers.push(user.id);
      })
      for (let i = 0; i < data.user.length; i++) {
        if (followers.includes(data.user[i].id)) {
          continue;
        }
        followers.push(data.user[i].id);
      }
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_attention: followers.join()
      })
    },
    visibilitySelect(param) {
      const index = this.okrObj.findIndex(item => item.ob_id == this.object_id)
      let visible = '';
      param.user.map(u => {
        visible += 'u' + u.id + ','
      });
      param.depart.map(d => {
        visible += 'd' + d.id + ','
      });
      let obj = {
        ob_id: this.currentOkr.ob_id,
        ob_visible_type: 3,
        ob_visible_range: visible
      }
      this.updateOkr(obj);
     },
    selectVisible(value) {
      if (!this.disableEdit) {
      if (value == 3) {
        this.$refs.visibility.openDialog(this.currentOkr.ob_visible_range);
        // this.visibleType = visibleType[0].dbformat;
      } else {
        let obj = {
          ob_id: this.currentOkr.ob_id,
          ob_visible_type: value,
        }
        this.updateOkr(obj);
        // this.selectedVisible = param;
      }
      // this.align_target = param;
      setTimeout(() => {
        this.isShowVisible = false;
      }, 100);
    }},
    // hideVisibleSelectBox() {
    //     this.isShowVisible = false;
    // },
    // showVisibleSelectBox() {
    //     this.isShowVisible = true;
    // },
    getUserName(id) {
      const index = this.users.findIndex(user => user.id == id);
      if (index !== -1) {
        return this.users[index].employeeName;
      }
      return '';
    },
    getDepartName(id) {
      const index =  this.departments.findIndex(departItem =>  departItem.id == id )
        if(index !== -1){
          return this.departments[index].departmentOaName
        }
        return ''
    },
    setDepartment(e) {
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_type_department: e.data.id
      });
    },
    setObjectType() {
      this.updateOkr({
        ob_id: this.currentOkr.ob_id,
        ob_type: this.objectType
      });
      this.showTypeForm = false;
    }
  }
}
</script>

<style scoped>
.info-container {
  width: 33%;
}

.cycle-container {
  position: relative;
}

.cycle-container:hover {
  cursor: pointer;
}

.cyclePicker {
  position: absolute;
  top: 30px;
  left: 0;
  border: 1px solid #aaa;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 12;
}

.object-name input {
  box-sizing: border-box;
  position: relative;
  background: rgb(255, 255, 255);
  cursor: text;
  border: 1px solid rgb(233, 236, 240);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 7px 7px;
  transition: border 0.2s ease 0.2s, box-shadow 0.2s ease 0.2s;
  color: #3f4755 !important;
}

.object-name input:focus {
  outline: none;
  border: 1px solid rgb(40, 121, 255);
  box-shadow: rgb(40 121 255 / 30%) 0px 0px 6px 0px inset;
}

.input-container {
  width: 100%;
}

.input-container input {
  width: 100%;
}

.object-name .v-icon {
  cursor: pointer;
}

.obj-editable {
  width: 100%;
}

.obj-cycle {
  word-break: break-all;
  white-space: nowrap;
  font-family: -apple-system, Arial, Verdana, Helvetica Neue, Helvetica, sans-serif;
  color: #89919f;
  font-size: 12px;
  line-height: 18px;
}

.object-name {
  color: #141c28;
  font-size: 20px;
  line-height: 24px;
  font-family: -apple-system, Arial, Verdana, Helvetica Neue, Helvetica, sans-serif;
  vertical-align: middle;
  word-break: break-all;
  font-weight: 600;
  margin-right: 8px;
  width: 100%;
}

.key-result-input input {
  box-sizing: border-box;
  position: relative;
  background: rgb(255, 255, 255);
  cursor: text;
  border: 1px solid rgb(233, 236, 240);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 7px 7px;
  transition: border 0.2s ease 0.2s, box-shadow 0.2s ease 0.2s;
  color: #3f4755 !important;
}

.key-result-input input:focus {
  outline: none;
  border: 1px solid rgb(40, 121, 255);
  box-shadow: rgb(40 121 255 / 30%) 0px 0px 6px 0px inset;
}

.visibleShow {
  width: 100%;
  min-height: 40px;
  margin-top: -66px;
  border-radius: 5px;
  border: 1px solid #999;
  padding: 7px 10px;
}

.visible-text {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0px 10px;
  word-break: break-word;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  /* overflow: hidden; */
}

.visible-select-box {
  position: absolute;
  left: 0;
  top: 5px;
  border: 1px solid #fff;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 5px #ddd;
  width: 100%;
  font-size: 14px;
}

.visible-select-box div:hover {
  cursor: pointer;
  background: #eee;
}

.f-14 {
  font-size: 14px !important;
}

.label {
  width: 70px;
}

.selected-visible {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-control {
  border: none !important;
}
.select_data{
  max-width: 254px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
