import { render, staticRenderFns } from "./OKRsComponent.vue?vue&type=template&id=304d3280&scoped=true&"
import script from "./OKRsComponent.vue?vue&type=script&lang=js&"
export * from "./OKRsComponent.vue?vue&type=script&lang=js&"
import style0 from "./OKRsComponent.vue?vue&type=style&index=0&id=304d3280&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304d3280",
  null
  
)

export default component.exports