<template>
<v-container align-center justify-start d-flex class="right_progress_info h100">
  <v-layout align-center justify-start column class="h100">
    <v-flex class="w100">
      <v-sheet class="h100">
        <v-container align-center justify-start d-flex class=" p_0">
          <v-layout align-center justify-start column class="">
            <!-- <Operating ref="operator" :object_id="object_id" /> -->
						<Operating ref="operator" :op_obj="op_obj" />
            <!-- <Intercom :object_id="object_id" @displayHiddenBtns="handle_displayHiddenBtns" :goal="Goal" /> -->
            <Intercom :ic_obj="this.ic_obj" :goal="this.goal" />
          </v-layout>
        </v-container>
      </v-sheet>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
// import Operating from '@/components/okrgoal/rightInfo/interactive/operating/Operating.vue';
// import Intercom from '@/components/okrgoal/okrDetail/Interactive/Intercom.vue';
// import Intercom from "@/components/common/Intercom/Intercom.vue";

export default {
  name: 'InteractiveComponent',
  props: ['object_id', 'goal'],
  data() {
    return {
      Goal: null,
      ic_obj:{},
			op_obj:{}
    }
  },
  created() {
    this.Goal = this.goal;
    
    this.ic_obj = {
        ic_parent: this.object_id,
        ic_parent_type: 0,
    };
		this.op_obj = {
        op_parent: this.object_id,
        op_parent_type: 0,
    };
  },
  watch: {
    goal(v) {
      this.Goal = v;
    },
    object_id(value, oldValue) {
      this.ic_obj = {
          ic_parent: value,
          ic_parent_type: 0,
      };
    },
  },
  components: {
    Operating:() => import('@/components/common/operating/Operating.vue'),
    Intercom:() => import('@/components/common/Intercom/Intercom.vue'),   
  },
  methods: {
    handle_displayHiddenBtns(prop) {
      this.$emit("displayHiddenBtns", prop);
    }
  }
}
</script>

<style scoped>
.right_progress_info {
  background-color: white;
  margin-top: 20px;
  padding-bottom: 0px !important;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.p_0 {
  padding: 0px !important;
}
</style>
