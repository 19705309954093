<template>
<span>
  <div class="d-flex justify-space-between align-center mb-4">
    <div>列表</div>
    <div>
      <date-range-picker ref="picker" opens="left" :locale-data="{ 
                        firstDay: 1, 
                        format: 'yyyy-mm-dd',
                        applyLabel: '确定',
                        cancelLabel: '取消',
                        daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                        monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                    }" :minDate="minDate" :maxDate="maxDate" v-model="dateRange" :ranges="false" @update="datePicker">
        <template v-slot:input="picker" style="min-width: 350px;">
          <v-icon style="font-size: 18px;">mdi-calendar</v-icon>
          <span>时间筛选</span>
          <!-- {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }} -->
        </template>
      </date-range-picker>
    </div>
  </div>
  <div class="d-flex justify-space-between align-center mt-4" v-for="r in reports">
    <v-icon  class="pa-1 report-mark">mdi-file-chart</v-icon>
    <div class="pl-3 text-subtitle-1">({{r.start}} - {{r.end}})</div>
    <v-spacer></v-spacer>
    <a @click="onShareReport(r)">
      <v-icon class="text-subtitle-1 icon-1">mdi-file-multiple-outline</v-icon>
      <span class="text-subtitle-1">分享</span>
    </a>
    <a @click="onEReportPage(r)" class="ml-4">
      <span class="text-subtitle-1">查看详情</span>
      <v-icon class="text-subtitle-1 icon-1">mdi-chevron-right-circle-outline</v-icon>
    </a>
  </div>
  <div v-if="!reports.length" class="text-center mt-8">
    <div class="empty py-8">
      <div>
        没有找到你想要的
      </div>
      <a>
        <v-icon>mdi-hand-pointing-right</v-icon>
        <span>了解E-周报</span>
      </a>
    </div>
  </div>
	<ShareReportDlg ref="ShareReportDlg" :ic_obj="{ic_parent:1,ic_parent_type:19}"/>
</span>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  Routes
} from "@/constants/routes.js";

export default {
  name: 'EWeeklyComponent',
  components: {
    DateRangePicker,
		ShareReportDlg: () => import("@/components/okrgoal/okrDetail/E-weekly/ShareReportDlg.vue")
  },
  props: ['object_id'],
  data() {
    return {
      dateRange: {},
      minDate: '2018-01-01',
      maxDate: '2050-12-31',
    }
  },
  mounted() {
    this.dateRange = {
      startDate: new Date(this.currentOkr.ob_start_date),
      endDate:new Date( this.currentOkr.ob_end_date),
    };

  },
  computed: {
    ...mapGetters('okr', ['okrObj']),
    ...mapGetters("user", ["users"]),
    currentOkr: function () {
      const index = this.okrObj.findIndex(item => item.ob_id == this.object_id)
      return this.okrObj[index]
    },
		reports(){
			let report = [];
			if(!this.currentOkr.ob_start_date || !this.dateRange.startDate){
				return report;
			}
			let nowDate = new Date();
			let obStart =new Date(this.currentOkr.ob_start_date) > this.dateRange.startDate ? new Date(this.currentOkr.ob_start_date): this.dateRange.startDate ;
			let obEnd = new Date(this.currentOkr.ob_end_date) < this.dateRange.endDate ? new Date(this.currentOkr.ob_end_date): this.dateRange.endDate;
			// obEnd.setDate(obEnd.getDate() + 1);
			// obStart.setDate(obStart.getDate() + (8 - obStart.getDay()) % 7);
			// let newEnd = new Date(obStart);
			// newEnd.setDate(newEnd.getDate() + 6);
			
			obEnd = obEnd>nowDate?nowDate:obEnd;
			obEnd.setDate(obEnd.getDate()  + (8 - obEnd.getDay()) % 7 - 6);
			// obStart.setDate(obStart.getDate() + (8 - obStart.getDay()) % 7);
			let newEnd = new Date(obEnd);
			newEnd.setDate(newEnd.getDate() - 6);

			// while (newEnd < obEnd && newEnd<nowDate) {
				report.push({
					start: moment(newEnd).format("YYYY/MM/DD"),
					end: moment(obEnd).format("YYYY/MM/DD"),
					startDate: new Date(obStart),
					endDate: new Date(newEnd),
				});
				obStart.setDate(obStart.getDate() + 7);
				newEnd.setDate(newEnd.getDate() + 7);
			// }

			report = report.reverse();
			return report;
		}
  },
  methods: {
    // ...mapActions("okr", ["updateOkr", "createKeyResult"]),
		onEReportPage(param){
			let route = this.$router.resolve({
        path: Routes.OKR_EREPORT_PAGE + '?objectId='+this.currentOkr.ob_id+'&workId='+param.startDate.getTime()/1000/60/60/24,
      });
      window.open(route.href, '_blank');
		},
		onShareReport(param){
			this.$refs.ShareReportDlg.open({ob_name: this.currentOkr.ob_name, e_start:param.start, e_end:param.end, path: Routes.OKR_EREPORT_PAGE + '?objectId='+this.currentOkr.ob_id+'&workId='+param.startDate.getTime()/1000/60/60/24 });
		},
    datePicker(e) {
			
    },
  }
}
</script>

<style scoped>
.empty {
  min-height: 50px;
  width: 80%;
  border: 1px dotted #777;
  margin: 0 auto;
}
.report-mark{
	font-size: 18px; background: rgb(12, 201, 145);
    border-radius: 3px;
    color: white;
}

.icon-1{
	color: rgb(40, 121, 255);
}
</style>
